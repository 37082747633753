var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2"},[_c('b-form-group',[_c('x-form-label',{attrs:{"label":"组件名称","require":""}}),_c('validation-provider',{attrs:{"name":"组件名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"readonly":_vm.isView,"placeholder":"请输入组件名称"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("组件名称不能为空")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('x-form-label',{attrs:{"label":"组件类型","require":""}}),_c('validation-provider',{attrs:{"name":"组件类型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vue-select2","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeList,"label":"name","placeholder":"请选择组件类型","reduce":function (option) { return option.id; },"disabled":!_vm.isNew},on:{"option:selected":function($event){return _vm.selectAction()}},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}},[_c('template',{slot:"no-options"},[_vm._v(_vm._s(_vm.$t('noData')))])],2),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("组件类型不能为空")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('x-form-label',{attrs:{"label":"集群","require":""}}),_c('validation-provider',{attrs:{"name":"集群","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"shareCluster","value":"true","checked":"","disabled":""},model:{value:(_vm.shareCluster),callback:function ($$v) {_vm.shareCluster=$$v},expression:"shareCluster"}},[_vm._v(" 共享配置 ")]),_c('b-form-radio',{attrs:{"name":"shareCluster","value":"false","disabled":""},model:{value:(_vm.shareCluster),callback:function ($$v) {_vm.shareCluster=$$v},expression:"shareCluster"}},[_vm._v(" 独立配置 ")])],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("请选择集群配置")]):_vm._e()]}}])})],1),_c('configuration-form',{attrs:{"formData":_vm.formData,"isNew":_vm.isNew,"isView":_vm.isView,"certificateList":_vm.certificateList}}),_c('b-form-group',[_c('label',[_c('span',{staticClass:"font-medium-1 x-text-bold text-body"},[_vm._v("描述:")])]),_c('validation-provider',{attrs:{"name":"描述"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"请输入描述"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})]}}])})],1),(!_vm.isView)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" 确定 ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }