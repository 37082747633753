<template>
    <validation-observer ref="refFormObserver">
      <b-form class="p-2">
        <b-form-group>
          <x-form-label label="组件名称" require/>
          <validation-provider
            #default="{ errors }"
            name="组件名称"
            rules="required"
          >
            <b-form-input
              v-model="formData.name"
              :state="errors.length > 0 ? false:null"
              :readonly="isView"
              placeholder="请输入组件名称"
            />
            <small class="text-danger" v-if="errors[0]">组件名称不能为空</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <x-form-label label="组件类型" require/>
          <validation-provider
            #default="{ errors }"
            name="组件类型"
            rules="required"
          >
            <v-select
              id="vue-select2"
              v-model="formData.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeList"
              label="name"
              placeholder="请选择组件类型"
              :reduce=" option => option.id"
              v-on:option:selected="selectAction()"
              :disabled="!isNew"
            >
              <template slot="no-options">{{$t('noData')}}</template>
            </v-select>
            <small class="text-danger" v-if="errors[0]">组件类型不能为空</small>
          </validation-provider>
        </b-form-group>
        <b-form-group>
          <x-form-label label="集群" require/>
          <validation-provider
            #default="{ errors }"
            name="集群"
            rules="required"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="shareCluster"
                name="shareCluster"
                value="true"
                checked
                disabled
              >
                共享配置
              </b-form-radio>
              <!--v-model="formData.shareCluster"-->
              <b-form-radio
                v-model="shareCluster"
                name="shareCluster"
                value="false"
                disabled
              >
                独立配置
              </b-form-radio>
            </div>
            <small class="text-danger" v-if="errors[0]">请选择集群配置</small>
          </validation-provider>
        </b-form-group>
        <configuration-form
          :formData="formData"
          :isNew="isNew"
          :isView="isView"
          :certificateList="certificateList"
        />
        <b-form-group>
          <label>
            <span class="font-medium-1 x-text-bold text-body">描述:</span>
          </label>
          <validation-provider
            #default="{ errors }"
            name="描述"
          >
            <b-form-textarea
              v-model="formData.description"
              :state="errors.length > 0 ? false:null"
              placeholder="请输入描述"
            />
          </validation-provider>
        </b-form-group>
        <b-button v-if="!isView"
          variant="primary"
          type="submit"
          @click.prevent="validationForm"
        >
          确定
        </b-button>
      </b-form>
    </validation-observer>
</template>

<script>
import XFormLabel from '@/@core/components/cx/table/XFormLabel.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BFormTextarea, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'
import { getCertificateListNoPage, add } from '@/api/device-access/networkComponentsList'
import configurationForm from './configurationForm.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BForm,
    BButton,
    BFormRadio,
    vSelect,
    configurationForm,
    XFormLabel,
  },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    network: {
      type: Object,
      default: () => {},
    },
    typeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      shareCluster: true,
      // 网络组件列表
      networkList: [],
      certificateList: [],
      formData: {
        name: undefined,
        type: undefined,
        shareCluster: true,
        configuration: {
          parserType: undefined,
          parserConfiguration: {
            delimited: undefined,
            lang: undefined,
            script: undefined,
            size: 0,
          },
        },
      },
      required,
    }
  },
  created() {
    getCertificateListNoPage().then(resp => {
      this.certificateList = resp.data.result
    })
  },
  watch: {
    visible(val) {
      if (val === true) {
        if (this.isNew === false) {
          this.formData = this.network
        } else {
          this.$refs.refFormObserver.reset()
          this.formData = {
            name: undefined,
            type: undefined,
            configuration: {
              parserType: undefined,
              parserConfiguration: {
                delimited: undefined,
                lang: undefined,
                script: undefined,
                size: 0,
              },
            },
          }
        }
      }
    },
  },
  methods: {
    validationForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.formData.shareCluster = true
          add(this.formData).then(() => {
            this.$xtoast.success('编辑成功！')
            this.$emit('editSuccess')
          })
        }
      })
    },
    // 选择组件类型
    selectAction() {
      this.formData.configuration = {
        parserType: undefined,
        parserConfiguration: {
          delimited: undefined,
          lang: undefined,
          script: undefined,
          size: 0,
        },
      }
    },
  },
}
</script>

<style lang="scss">
</style>
