<template>
  <div>
    <div class="x-anim-fade-in" v-if="formData.type === 'UDP'">
      <b-form-group>
        <x-form-label label="开启DTLS"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="dtls"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="dtls"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          id="vue-select2"
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="!isNew"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="私钥别名"/>
        <b-form-input
          v-model="formData.configuration.privateKeyAlias"
          placeholder="请输入私钥别名"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="远程地址"/>
        <b-form-input
          v-model="formData.configuration.remoteAddress"
          placeholder="请输入远程地址"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="远程端口"/>
        <b-form-input
          v-model="formData.configuration.remotePort"
          placeholder="请输入远程端口"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="本地地址"/>
        <b-form-input
          v-model="formData.configuration.localAddress"
          placeholder="请输入本地地址"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="本地端口"/>
        <b-form-input
          v-model="formData.configuration.localPort"
          placeholder="请输入本地端口"
          :readonly="isView"
        />
      </b-form-group>
    </div>
    <div class="x-anim-fade-in" v-if="formData.type === 'HTTP_CLIENT'">
      <b-form-group>
        <x-form-label label="baseUrl"/>
        <b-form-input
          v-model="formData.configuration.baseUrl"
          placeholder="请输入baseUrl"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="!isNew"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="开启SSL"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="ssl"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="ssl"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="验证HOST"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.verifyHost"
            name="verifyHost"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.verifyHost"
            name="verifyHost"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="信任所有证书"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.trustAll"
            name="trustAll"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.trustAll"
            name="trustAll"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
    </div>
    <div class="x-anim-fade-in" v-if="formData.type === 'WEB_SOCKET_SERVER'">
      <b-form-group>
        <x-form-label label="线程数"/>
        <b-form-input
          v-model="formData.configuration.instance"
          placeholder="请输入线程数"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="开启SSL"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="ssl"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="ssl"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="isView"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="HOST"/>
        <b-form-input
          v-model="formData.configuration.host"
          placeholder="请输入HOST"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="PORT"/>
        <b-form-input
          v-model="formData.configuration.port"
          placeholder="请输入PORT"
          :readonly="isView"
        />
      </b-form-group>
    </div>
    <div class="x-anim-fade-in" v-if="formData.type === 'TCP_SERVER' || formData.type === 'TCP_CLIENT'">
      <b-form-group>
        <x-form-label label="开启SSL"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="ssl"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="ssl"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="isView"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="HOST"/>
        <b-form-input
          v-model="formData.configuration.host"
          placeholder="请输入HOST"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="PORT"/>
        <b-form-input
          v-model="formData.configuration.port"
          placeholder="请输入PORT"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="解析方式" require />
        <i class="el-icon-question ml-50 text-secondary text-lighten-5" v-b-tooltip.hover.top="'处理TCP粘拆包的方式'"></i>
        <validation-provider
          #default="{ errors }"
          name="解析方式"
          rules="required"
        >
          <v-select
            id="vue-select3"
            v-model="formData.configuration.parserType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="analysisList"
            :state="errors.length > 0 ? false:null"
            label="name"
            placeholder="请选择解析方式"
            :reduce=" option => option.id"
          >
            <template slot="no-options">{{$t('noData')}}</template>
          </v-select>
          <small class="text-danger" v-if="errors[0]">解析方式不能为空</small>
        </validation-provider>
      </b-form-group>
      <b-form-group v-if="formData.configuration.parserType === 'delimited'">
        <x-form-label label="分隔符"/>
        <b-form-input
          v-model="formData.configuration.parserConfiguration.delimited"
          placeholder="请输入分隔符"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group v-if="formData.configuration.parserType === 'script'">
        <x-form-label label="脚本语言"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.parserConfiguration.lang"
            name="ssl"
            value="javascript"
            :disabled="isView"
          >
            JavaScript
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.parserConfiguration.lang"
            name="ssl"
            value="groovy"
            :disabled="isView"
          >
            Groovy
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group v-if="formData.configuration.parserType === 'script'">
        <x-form-label label="解析脚本"/>
        <div class="codeEditBox">
          <editor
            v-model="formData.configuration.parserConfiguration.script"
            @init="editorInit"
            lang="javascript"
            :options="editorOptions"
            :theme="theme"
          ></editor>
        </div>
      </b-form-group>
      <b-form-group v-if="formData.configuration.parserType === 'fixed_length'">
        <x-form-label label="长度值"/>
        <b-form-input
          v-model="formData.configuration.parserConfiguration.size"
          placeholder="长度值"
          :readonly="isView"
        />
      </b-form-group>
    </div>
    <div class="x-anim-fade-in" v-if="formData.type === 'MQTT_CLIENT'">
      <b-form-group>
        <x-form-label label="clientId" require/>
        <validation-provider
          #default="{ errors }"
          name="clientId"
          rules="required"
        >
        <b-form-input
          v-model="formData.configuration.clientId"
          :state="errors.length > 0 ? false:null"
          placeholder="请输入客户端ID"
          :readonly="isView"
        />
          <small class="text-danger" v-if="errors[0]">客户端ID不能为空</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <x-form-label label="HOST" require/>
        <validation-provider
          #default="{ errors }"
          name="HOST"
          rules="required"
        >
        <b-form-input
          v-model="formData.configuration.host"
          :state="errors.length > 0 ? false:null"
          placeholder="请输入HOST"
          :readonly="isView"
        />
          <small class="text-danger" v-if="errors[0]">HOST不能为空</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <x-form-label label="PORT" require/>
        <validation-provider
          #default="{ errors }"
          name="PORT"
          rules="required"
        >
        <b-form-input
          v-model="formData.configuration.port"
          :state="errors.length > 0 ? false:null"
          placeholder="请输入PORT"
          :readonly="isView"
        />
          <small class="text-danger" v-if="errors[0]">PORT不能为空</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <x-form-label label="TLS"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.TLS"
            name="TLS"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.TLS"
            name="TLS"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="isView"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="用户名"/>
        <b-form-input
          v-model="formData.configuration.username"
          placeholder="请输入用户名"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="密码"/>
        <b-form-input
          v-model="formData.configuration.password"
          placeholder="请输入用户名"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="最大消息长度"/>
        <b-form-input
          v-model="formData.configuration.maxMessageSize"
          placeholder="请输入最大消息长度"
          :readonly="isView"
        />
      </b-form-group>
    </div>
    <div class="x-anim-fade-in" v-if="formData.type === 'HTTP_SERVER'">
      <b-form-group>
        <x-form-label label="PORT"/>
        <b-form-input
          v-model="formData.configuration.port"
          placeholder="请输入PORT"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="isView"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="TLS"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.tls"
            name="tls"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="tls"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
    </div>
    <div class="x-anim-fade-in" v-if="formData.type === 'MQTT_SERVER'">
      <b-form-group>
        <x-form-label label="线程数"/>
        <b-form-input
          v-model="formData.configuration.instance"
          placeholder="请输入线程数"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="isView"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="开启SSL"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="ssl"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.ssl"
            name="ssl"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="isView"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="HOST" require/>
        <validation-provider
          #default="{ errors }"
          name="HOST"
          rules="required"
        >
          <b-form-input
            v-model="formData.configuration.host"
            :state="errors.length > 0 ? false:null"
            placeholder="请输入HOST"
            :readonly="isView"
          />
          <small class="text-danger" v-if="errors[0]">HOST不能为空</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <x-form-label label="PORT" require/>
        <validation-provider
          #default="{ errors }"
          name="PORT"
          rules="required"
        >
          <b-form-input
            v-model="formData.configuration.port"
            :state="errors.length > 0 ? false:null"
            placeholder="请输入PORT"
            :readonly="isView"
          />
          <small class="text-danger" v-if="errors[0]">PORT不能为空</small>
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <x-form-label label="最大消息长度"/>
        <b-form-input
          v-model="formData.configuration.maxMessageSize"
          placeholder="请输入最大消息长度"
          :readonly="isView"
        />
      </b-form-group>
    </div>
    <div class="x-anim-fade-in" v-if="formData.type === 'WEB_SOCKET_CLIENT'">
      <b-form-group>
        <x-form-label label="uri"/>
        <b-form-input
          v-model="formData.configuration.uri"
          placeholder="请输入uri"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="开启SSL"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.ssl"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.ssl"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="验证HOST"/>
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="formData.configuration.verifyHost"
            :value="true"
            :disabled="isView"
          >
            是
          </b-form-radio>
          <b-form-radio
            v-model="formData.configuration.verifyHost"
            :value="false"
            :disabled="isView"
          >
            否
          </b-form-radio>
        </div>
      </b-form-group>
      <b-form-group>
        <x-form-label label="证书"/>
        <v-select
          v-model="formData.configuration.certId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="certificateList"
          label="name"
          placeholder="请选择证书"
          :reduce=" option => option.id"
          :disabled="isView"
        >
          <template slot="no-options">{{$t('noData')}}</template>
        </v-select>
      </b-form-group>
      <b-form-group>
        <x-form-label label="HOST"/>
        <b-form-input
          v-model="formData.configuration.host"
          placeholder="请输入HOST"
          :readonly="isView"
        />
      </b-form-group>
      <b-form-group>
        <x-form-label label="PORT"/>
        <b-form-input
          v-model="formData.configuration.port"
          placeholder="请输入PORT"
          :readonly="isView"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput, BFormGroup, BFormRadio, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'
import Editor from 'vue2-ace-editor'
import usAppConfig from '@core/app-config/useAppConfig'
import XFormLabel from '@/@core/components/cx/table/XFormLabel.vue'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BFormRadio,
    vSelect,
    XFormLabel,
    Editor,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    certificateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 脚本编辑器
      editorOptions: {
        // 设置代码编辑器的样式
        enableBasicAutocompletion: true, // 启用基本自动完成
        enableSnippets: true, // 启用代码段
        enableLiveAutocompletion: true, // 启用实时自动完成
        tabSize: 2, // 标签大小
        fontSize: 18, // 设置字号
        showPrintMargin: false, // 去除编辑器里的竖线
      },
      // 系统主题
      themeConfig: usAppConfig().skin.value,
      // 脚本编辑器主题
      theme: 'pastel_on_dark',
      required,
      analysisList: [
        {
          id: 'DIRECT',
          name: '不处理',
        },
        {
          id: 'delimited',
          name: '分隔符',
        },
        {
          id: 'script',
          name: '自定义脚本',
        },
        {
          id: 'fixed_length',
          name: '固定长度',
        },
      ],
    }
  },
  watch: {
    themeConfig(val) {
      // 切换脚本编辑器的主题
      if (val === 'dark') {
        require('brace/theme/pastel_on_dark')
        this.theme = 'pastel_on_dark'
      } else {
        require('brace/theme/tomorrow')
        this.theme = 'tomorrow'
      }
    },
  },
  created() {
    if (this.themeConfig === 'dark') {
      require('brace/theme/tomorrow_night_eighties')
      this.theme = 'tomorrow_night_eighties'
    } else {
      require('brace/theme/tomorrow')
      this.theme = 'tomorrow'
    }
  },
  methods: {
    editorInit() {
      // require('brace/theme/solarized_dark')
      require('brace/ext/language_tools') // language extension prerequsite...
      require('brace/mode/yaml')
      require('brace/mode/json')
      require('brace/mode/less')
      require('brace/snippets/json')
      require('brace/mode/lua')
      require('brace/snippets/lua')
      require('brace/mode/javascript')
      require('brace/snippets/javascript')
    },
  },
}
</script>
