import request from '@/auth/jwt/useJwt'

/*  查询组件分类  */
export function queryTypes() {
  return request.axiosIns({
    url: '/network/config/supports',
    method: 'get',
  })
}
export function query(pageIndex, pageSize, params) {
  let requestUrl = `network/config/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts[0].name=id&sorts[0].order=desc`
  let index = 0
  if (params) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name&terms[${index}].value=${params.name}`
      index++
    }
    if (params.type) {
      requestUrl += `&terms[${index}].column=type&terms[${index}].value=${params.type}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
/*  分页查询组件列表  */
export function queryPagesByTypes(pageNo, params) {
  return request.axiosIns({
    url: `network/config/_query?pageIndex=${pageNo}&pageSize=6&sorts[0].name=id&sorts[0].order=desc&terms%5B0%5D.column=type%24IN&terms%5B0%5D.value=${params}`,
    method: 'get',
  })
}
/*  无分页查询组件列表  */
export function queryNoPages() {
  return request.axiosIns({
    url: 'network/config/_query/no-paging?paging=false&sorts[0].name=id&sorts[0].order=desc',
    method: 'get',
  })
}

export function add(data) {
  return request.axiosIns({
    url: 'network/config',
    method: 'PATCH',
    data,
  })
}

export function edit(data) {
  return request.axiosIns({
    url: 'network/config',
    method: 'PATCH',
    data,
  })
}

export function remove(id) {
  return request.axiosIns({
    url: `network/config/${id}`,
    method: 'delete',
  })
}
export function getById(id) {
  return request.axiosIns({
    url: `network/config/${id}`,
    method: 'get',
  })
}
// 启动组件
export function start(id) {
  return request.axiosIns({
    url: `network/config/${id}/_start`,
    method: 'post',
  })
}
// 停止组件组件
export function shutdown(id) {
  return request.axiosIns({
    url: `network/config/${id}/_shutdown`,
    method: 'post',
  })
}
// 查询证书
export function getCertificateListNoPage() {
  return request.axiosIns({
    url: 'network/certificate/_query/no-paging?paging=false',
    method: 'get',
  })
}
