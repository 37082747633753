import { render, staticRenderFns } from "./configurationForm.vue?vue&type=template&id=6a2f9267&"
import script from "./configurationForm.vue?vue&type=script&lang=js&"
export * from "./configurationForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports