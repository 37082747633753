<template>
  <div style="height: inherit">
    <x-table
      title="网络组件"
      ref="networkTable"
      :card="true"
      tableType="list"
      :options="options"
      :hideSearchBar="false"
      @search="searchHandle"
      @rowStart="rowStart"
      @rowStop="rowStop"
      @rowDebug="rowDebug"
      @rowDelete="rowDelete">
      <template slot="actions">
        <b-button
          v-ripple.400="$x.ripple.white"
          variant="outline-primary"
          class="w-100 x-dashed-border x-border-primary"
          @click="toSider('add', null)">
          <feather-icon
            size="16"
            icon="PlusIcon"
            class="mr-50"
          />
          新增
        </b-button>
      </template>
      <template slot="row-view" slot-scope="scope">
        <b-button
          v-ripple.400="$x.ripple.primary"
          class="btn-icon rounded-circle"
          variant="flat-primary"
          @click="toSider('view', scope.row)">
          <feather-icon icon="EyeIcon" />
        </b-button>
      </template>
      <template slot="row-edit" slot-scope="scope">
        <b-button
          v-ripple.400="$x.ripple.primary"
          class="btn-icon rounded-circle"
          variant="flat-primary"
          @click="toSider('edit', scope.row)">
          <feather-icon icon="EditIcon" />
        </b-button>
      </template>
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="40%"
      :visible="visibleFlag"
      @hidden="sideHide"
    >
      <network-components-form
        :typeList="typeList"
        :network="network"
        :isNew="isNew"
        :isView="isView"
        :visible="visibleFlag"
        @editSuccess="editSuccess"
      />
    </b-sidebar>
    <b-modal
      ref="debugModal"
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="调试"
      cancel-title="取消"
      centered
      title="调试"
      size="lg"
      :visible="modalFlag"
      @hidden="modalHide"
    >
      <b-tabs
        vertical
        active-nav-item-class="bg-light-primary nav-vertical-w justify-content-start x-anim-fade-in"
        nav-wrapper-class="nav-vertical">
        <b-tab
          active
          title="订阅消息">
          <h1>订阅消息</h1>
        </b-tab>
        <b-tab
          title="订阅消息">
          <h1>推送消息</h1>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BSidebar, BButton, BModal, BTabs, BTab,
} from 'bootstrap-vue'
import {
  query, remove, queryTypes, start, shutdown,
} from '@/api/device-access/networkComponentsList'
import networkComponentsForm from './networkComponentsForm.vue'

export default {
  components: {
    XTable,
    BSidebar,
    networkComponentsForm,
    BButton,
    BModal,
    BTabs,
    BTab,
  },
  data() {
    return {
      modalFlag: false,
      // 搜索选择的类型
      checkTypes: undefined,
      // sider显隐
      visibleFlag: false,
      content: [{
        prop: 1,
        title: '第一步',
        content: '啊哈大口袋里',
      }, {
        prop: 2,
        title: '第二部',
        content: '打卡来看待',
      }],
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        rowSelectable: false,
        searchFold: true,
        lableVertical: true,
        // 新增按钮
        addBtn: false,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        cardColumnCols: 6,
        // showActions: false,
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon' },
          { action: 'edit', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'start', name: '启用', icon: 'PlayIcon' },
          { action: 'stop', name: '停止', icon: 'PauseIcon' },
          { action: 'debug', name: '调试', icon: 'SkipForwardIcon' }],
        actionFilter: (action, row) => {
          if (row.state.value === 'disabled') {
            return action === 'view' || action === 'edit' || action === 'delete' || action === 'start' || action === 'debug'
          }
          return action === 'view' || action === 'edit' || action === 'delete' || action === 'stop' || action === 'debug'
        },
        columns: [{
          label: '组件名称',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入组件名称',
          },
        }, {
          label: '组件类型',
          labelShow: true,
          prop: 'type',
          onDictSelected: (option, columns, formData) => {
            console.log(option, columns, formData)
          },
          type: 'select',
          dictUrl: '/network/config/supports',
          getDictLabel: option => option.label + `（${option.value})`,
          props: {
            label: 'name',
            value: 'id',
          },
          rules: {
            rule: 'required',
            message: '请选择通知类型',
          },
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          searchShow: false,
          addShow: false,
          editShow: false,
          rowSelectDot: true,
          dictData: [{ label: '已启动', value: 'enabled' }, { label: '已停止', value: 'disabled' }],
          selectVariant: prop => {
            switch (prop.value) {
              case 'disabled':
                return 'text-danger'
              case 'enabled':
              default:
                return 'text-success'
            }
          },
        },
        ],
      },
      isNew: true,
      isView: false,
      typeList: [],
      network: undefined,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    // sider隐藏
    sideHide() {
      this.visibleFlag = false
    },
    toSider(type, network) {
      queryTypes().then(resp => {
        this.typeList = resp.data.result
      })
      if (type === 'add') {
        this.isNew = true
        this.isView = false
      }
      if (type === 'edit') {
        this.network = network
        this.isNew = false
        this.isView = false
      }
      if (type === 'view') {
        this.network = network
        this.isNew = false
        this.isView = true
      }
      this.visibleFlag = true
    },
    editSuccess() {
      this.sideHide()
      this.$refs.networkTable.__searchHandle()
    },
    rowStart(data, done) {
      start(data.id).then(() => {
        done()
      })
    },
    rowStop(data, done) {
      shutdown(data.id).then(() => {
        done()
      })
    },
    rowDebug(data) {
      console.log(data)
      this.modalFlag = true
    },
    modalHide() {
      this.modalFlag = false
    },
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
